import React, {useState, useEffect} from 'react';
import { Autocomplete, Box, Card, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, Link, Radio, RadioGroup, Stack, Switch, TextField } from '@mui/material';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { apiSaveInvestorOpportunity, apiUpdateInvestorOpportunity, apiWallet, getInvestors, getMasterClass, uploadFile } from '../../utils/api/api';
import { formatDateTime, toLocalePrice, toLocalePriceNoDecimal } from '../../utils/StringUtil';
import { BASE_URL, DRAWDOWN_FAILED, DRAWDOWN_PENDING, DRAWDOWN_SENT, DRAWDOWN_UNSENT, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SchemeInvestorInfo = ({ open, onClose, investor }) => {
  const [commitment, setCommitment] = useState(0.00);
  const [upFront, setUpFront] = useState(0);
  const [upFrontAmount, setUpFrontAmount] = useState(0.00);
  const [gst, setGST] = useState(0);
  const [total, setTotal] = useState(0);
  const [masterClassList, setMasterClassList] = useState([]);
  const [cmRid, setCmRid] = useState(null);
  const [carry, setCarry] = useState(0.00);
  const [hurdle, setHurdle] = useState(0.00);
  const [drawdownNoticeStatus, setDrawdownNoticeStatus] = useState(0);
  const [consentRecieved, setConsentRecieved] = useState(false);
  const [unitAllotment, setUnitAllotment] = useState(false);
  const [remark, setRemark] = useState('');
  const [invOppFile, setinvOppFile] = useState({});
  const [dematCharges, setDematCharges] = useState(0.00);
  const { enqueueSnackbar } = useSnackbar();

  const getMasterClassList = (param = {}) => {
    param.status = 1;
    getMasterClass (param)
      .then((data) => {
        setMasterClassList(data.classMasters);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   const masterClass = masterClassList.find((option) => option.cmRid === cmRid) || null;
  //   if(masterClass){
  //     setCarry(masterClass.carry);
  //     setHurdle(masterClass.hurdleRate);
  //   }
  // }, [cmRid]);

  useEffect(() => {
    if(open){
      getMasterClassList();
      setCommitment(investor?.commitment ?? 0.00);
      setUpFront(investor?.upfront ?? 0.00)
      setUpFrontAmount(investor?.upfrontAmount ?? 0.00);
      setGST(investor?.gst ?? 0.00);
      setTotal(investor?.totalAmount ?? 0.00);
      setCmRid(investor?.cmRid);
      setCarry(investor?.carry);
      setHurdle(investor?.hurdleRate);
      setDrawdownNoticeStatus(investor?.drawdown ?? 0);
      setConsentRecieved(investor?.consent ?? 0);
      setUnitAllotment(investor?.unitAllotted ?? 0);
      setRemark(investor?.remark ?? '');
      setinvOppFile(JSON.parse(investor?.invOppFiles) ?? null);
      setDematCharges(investor?.dematCharges ?? 0.00);
    }
  }, [open]);

  return (
    <DrawerLayout
      open={open}
      drawerWidth="50%"
      title={investor?.invFullName}
      subtitle={investor?.invCode}
      closeAction={onClose}
      positiveAction={
        {label: 'Close', onClick : onClose}
      }>
      <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
        <div className="fs-med-regular p-0 m-0">
          <span className="fs-med-regular text-muted p-0 m-0">Commitment : </span>₹{toLocalePrice(commitment)}
        </div>
      </Stack>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
        <p className="section-label p-0 m-0">Upfront</p>
        
        <Stack direction="row" sx={{flexWrap: 'wrap', gap: 5}}>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Amount : </span>{toLocalePrice(upFrontAmount)}
            <span className="fs-small-regular text-muted ms-1">{toLocalePrice(upFront)}%</span>
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Statutory Fees : </span>{toLocalePrice(gst)}
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Demat Charges : </span>{toLocalePrice(dematCharges)}
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Total Amount : </span>{toLocalePrice(total)}
          </div>
        </Stack>
      </Stack>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
        <p className="section-label p-0 m-0">Class</p>
        
        <Stack direction="row" sx={{flexWrap: 'wrap', gap: 5}}>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0"></span>{investor?.className}
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Carry : </span>{carry}%
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="fs-med-regular text-muted p-0 m-0">Hurdle : </span>{hurdle}%
          </div>
        </Stack>
      </Stack>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Stack sx={{ marginBottom: "1rem", gap: 2 }} direction="column" >
        <Stack direction="row" sx={{gap: 5}}>
          <div className="fs-med-regular p-0 m-0">
            <span className="section-label p-0 m-0">Drawdown : </span>
            {drawdownNoticeStatus === DRAWDOWN_UNSENT ? 'UnSent' :
              drawdownNoticeStatus === DRAWDOWN_SENT && (investor?.drawdownFile ?
              <Link color="secondary" target="_blank" href={`${BASE_URL}/${JSON.parse(investor?.drawdownFile).path}`}>Sent</Link> : 'Sent'
              )
            }
            {drawdownNoticeStatus === DRAWDOWN_PENDING ? 'Pending' : ''}
            {drawdownNoticeStatus === DRAWDOWN_FAILED ? 'Failed' : ''}
          </div>
          <div className="fs-med-regular p-0 m-0">
            <span className="section-label p-0 m-0">Consent : </span>{consentRecieved ? 'Recieved' : 'Pending'}
          </div>
        </Stack>
        {!consentRecieved || (consentRecieved && remark.trim() === '')  ? '' : 
        <div className="fs-med-regular p-0 m-0">
          <span className="fs-med-regular text-muted p-0 m-0">Consent Remark : </span>{remark}
        </div>}
      </Stack>
      <Divider sx={{ marginBottom: "1rem" }} />

      {/* <Stack sx={{ marginBottom: "2rem" }} direction="column" className={drawdownNoticeStatus ? '' : 'd-none'}>
        <p className="section-label mb-2">Send Drawdown Notice</p>
        <p className="fs-med-medium p-0 m-0">
          <CheckCircleIcon color="success" className="me-2" /> Sent</p>
      </Stack> */}
      
      <Stack sx={{ marginBottom: "1rem" }} direction="column" className={investor?.ipAddress === null ? 'd-none' : ''}>
        <p className="section-label mb-2">Consent Details</p>
        <Stack direction="row" spacing={2}>
          <div className="d-flex gap-3 flex-grow-1">
            {investor?.lat !== null && investor?.lon !== null &&
            <Chip
              variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">LOCATION : </span>
                  <Link>{investor?.lat}, {investor?.lon}</Link>
                </div>
              }
              onClick={()=> window.open(`https://maps.google.com/maps?q=${investor?.lat},${investor?.lon}`, "_blank")}
            /> }
            {investor?.ipAddress !== null &&
            <Chip
            variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">IP : </span>
                  {investor?.ipAddress}
                </div>
              }
            /> }
            {investor?.consentDate !== null &&
            <Chip
            variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">DATE & TIME : </span>
                  {formatDateTime(investor?.consentDate, true)}
                </div>
              }
            /> }
          </div>
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "1rem" }} direction="column" className={unitAllotment ? '' : 'd-none'}>
        <p className="section-label mb-2">Unit Allotment</p>
        <Stack direction="row" spacing={2} sx={{alignItems: 'start'}}>
          <p className="fs-med-medium">
            <CheckCircleIcon color="success" className="me-2" />
            {invOppFile === null || Object.keys(invOppFile).length === 0 ? 'Alloted' :
              <Link color="secondary" target="_blank" href={`${BASE_URL}/${invOppFile?.path}`}>Alloted</Link>}
            </p>
          </Stack>
      </Stack>

      <Divider sx={{ marginBottom: "1rem" }} />
      <Stack sx={{ marginBottom: "1rem" }} direction="column">
        <p className="section-label mb-2">Record Demat Details</p>

        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>ISIN Code</p>
              <p className="fs-med-medium p-0 m-0">{investor?.isin ?? '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>CDSL / NSDL</p>
              <p className="fs-med-medium p-0 m-0">{investor?.nsdlCdsl ?? '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Unit Alloted</p>
              <p className="fs-med-medium p-0 m-0">{investor?.unitsAllotted ? toLocalePriceNoDecimal(investor?.unitsAllotted) : '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Demat Credit Date</p>
              <p className="fs-med-medium p-0 m-0">{investor?.dematCreditDate ? formatDateTime(investor?.dematCreditDate, false) : '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Remark</p>
              <p className="fs-med-medium p-0 m-0">{investor?.dematerializationRemark ?? '---'}</p>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Additional Charges</p>
              <p className="fs-med-medium p-0 m-0">{investor?.aditionalCharges ? toLocalePriceNoDecimal(investor?.aditionalCharges) : '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Charges Date</p>
              <p className="fs-med-medium p-0 m-0">{investor?.chargesDatetime ? formatDateTime(investor?.chargesDatetime, false) : '---'}</p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>Aditional Charges Remark</p>
              <p className="fs-med-medium p-0 m-0">{investor?.aditionalChargesNarration ?? '---'}</p>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </DrawerLayout>
  );
}

export default SchemeInvestorInfo;