
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PREVIEW_URL = process.env.REACT_APP_PREVIEW_URL;
export const SPRINTVC_INVESTOR_URL = process.env.REACT_APP_PREVIEW_URL.replace("/preview", "");
// export const BASE_URL = 'http://192.168.29.40:8080';

export const DD_TYPE_PROJECT_DOMAIN = 1;

export const DD_TYPE_INVESTMENT_TYPE = 2;

export const DD_TYPE_STAGE_OF_COMPANY = 3;

export const DD_TYPE_INV_ROUND_TYPE = 4;

export const DD_TYPE_GEOGRAPHY = 5;

export const DD_TYPE_OPPORTUNITY_STATUS = 6;

export const DD_TYPE_INVESTOR_TYPE = 7;

export const DD_TYPE_BANK_TYPE = 8;

export const DD_TYPE_SOURCE_TYPE = 9;

export const DD_TYPE_AUTHOR = 10;
export const DD_TYPE_TAGS = 11;
export const DD_TYPE_PATNERS = 12;
export const DD_TYPE_TRANSACTION_FILES = 13;
export const DD_TYPE_MCA_DEL_REASON = 14;
export const DD_TYPE_DP_NAMES = 15;
export const DD_TYPE_SCHEME_EXPENSES=16
export const DD_TYPE_NOMINATION_DEL_REASON=17

export const STATUS_SUCCESS = true;

export const STATUS_INACTIVE = 0;

export const SNACKBAR_SUCCESS = {variant: "success"};

export const SNACKBAR_WARNING = {variant: "warning"};

export const SNACKBAR_ERROR = {variant: "error"};

export const SNACKBAR_INFO = {variant: "info"};


export const RECORD_DISPLAY_LIMIT = 20;

export const DRAWDOWN_UNSENT = 0;
export const DRAWDOWN_SENT = 1;
export const DRAWDOWN_PENDING = 2;
export const DRAWDOWN_FAILED = 3;

export const GENERATED_BY_USER = 0;
export const GENERATED_BY_ADMIN = 1;

export const TINY_MCE_API_KEY = "5gmcnikvkgmu9jn1u5or966swqrj06q1xr4eu3lgvkpk0n7a";

export const USER_TYPE_ADMIN = 1;

export const USER_TYPE_INVESTOR = 2;

export const USER_TYPE_PARTNER = 3;

export const USER_TYPE_SUB_ADMIN = 4;

export const TAB_ALL_APPROVED = 0;
export const TAB_PENDING = 1;
export const TAB_DEMAT = 2;

export const ESCROW = 'ESCROW';
export const NSDL = 'NSDL';
export const CDSL = 'CDSL';

export const DEMAT_CHARGES = 1180;