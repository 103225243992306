import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import InvestorModal from "./InvestorModal";
import {
  apiDeleteInvestor,
  apiGetSilentLoginToken,
  apiInvestorDetails,
  apiInvestorListDownload,
  apiUpdateInvestor,
  getDataDictionaryValues,
  getInvestors,
  getPartners,
  investorEdit,
} from "../../utils/api/api";
import { useSnackbar } from "notistack";
import {
  DD_TYPE_SOURCE_TYPE,
  PREVIEW_URL,
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AlertLayout from "../../components/layout/AlertLayout";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MCADrawer from "./MCADrawer";
import InvestorLogin from "./InvestorLogin";
import CircleIcon from "@mui/icons-material/Circle";
import InvestorInfo from "./InvestorInfo";
import { isSubAdmin } from "../../utils/StringUtil";
import GetAppIcon from '@mui/icons-material/GetApp';
import NominationDrawer from "./NominationDrawer";

const Investor = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [investorList, setInvestorList] = useState([]);
  const [editInvestor, setEditInvestor] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filterCkyc, setFilterCkyc] = useState(-1);
  const [filterMca, setFilterMca] = useState(-1);
  const [filterStamp, setFilterStamp] = useState(-1);
  const [filterPartner, setFilterPartner] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSortMenu = Boolean(anchorElSort);
  const [sortInv, setSortInv] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilterMenu = Boolean(anchorElFilter);
  const userType = process.env.REACT_APP_USER_TYPE;
  const [partnerRid, setPartnerRid] = useState(null);
  const [partnerList, setPartnerList] = useState([]);
  const [openInvestorInfo, setOpenInvestorInfo] = useState(false);

  const [dematFilters, setDematFilters] = useState({});
  const [anchorElExport, setAnchorElExport] = React.useState(null);

  const [anchorElMap, setAnchorElMap] = useState({});
  const handleMenuOpen = (event, index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };
  const handleMenuClose = (index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };

  const handleCkycFilter = (value) => {
    setFilterCkyc(value);
  };
  const handleMcaFilter = (value) => {
    setFilterMca(value);
  };
  const handleStampFilter = (value) => {
    setFilterStamp(value);
  };

  const handleSortInv = (value) => {
    setSortInv(value);
    setAnchorElSort(null);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditInvestor({});
  };

  const handleEdit = async (id) => {
    const resData = await apiInvestorDetails({invRid: id});
    if(resData){
      setEditInvestor(resData);
      setOpen(true);
      setIsEdit(true);
    }
  };

  const handleSave = () => {
    setPage(1);
    getInvestorList();
    handleClose();
  };

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };
  const handleAlertDialog = (params = {}) => {
    setAlertLoader(true);
    params.invRid = editInvestor.invRid;
    params.userRid = editInvestor.userRid;
    apiDeleteInvestor(params)
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleAlertDialog();
        setPage(1);
        getInvestorList();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getInvestorList = (param = {}) => {
    setIsLoading(true);
    param.pageNumber = page;
    param.name = searchTerm.trim();
    param.recLimit = RECORD_DISPLAY_LIMIT;
    param.ckycStatus = filterCkyc < 0 ? null : filterCkyc;
    param.mcaStatus = filterMca < 0 ? null : filterMca;
    param.stamp = filterStamp < 0 ? null : filterStamp;
    param.partnerRid = filterPartner.map((item) => item).join(",");
    param.sort = sortInv;
    
    const reqParams = {
      ...param,
      ...dematFilters,
      isActive: parseInt(dematFilters?.isActive) === 1
        ? true
        : parseInt(dematFilters?.isActive) === 0
        ? false
        : null,
      dematPending: parseInt(dematFilters?.dematPending) === 1
        ? true
        : parseInt(dematFilters?.dematPending) === 0
        ? false
        : null
    };
    getInvestors(reqParams)
      .then((data) => {
        setIsLoading(false);
        setInvestorList(data.investorList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const toggleInvestorInfo = async (invDetails) => {
    if (openInvestorInfo) {
      setOpenInvestorInfo(false);
    } else {
      const resData = await apiInvestorDetails({invRid: invDetails?.invRid});
      if(resData){
        setEditInvestor(resData);
        setOpenInvestorInfo(true);
      }
    }
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getInvestorList();
      } else {
        return;
      }
    } else {
      getInvestorList();
    }
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getInvestorList();
  }, [page, filterCkyc, filterMca, filterStamp, sortInv, filterPartner, dematFilters]);

  useEffect(() => {
    getPartnersList();
  }, []);

  const deleteUser = (invDetails) => {
    setEditInvestor(invDetails);
    toggleAlertDialog();
  };

  const openInvPreview = (userRid) => {
    apiGetSilentLoginToken(userRid).then((res) => {
      if (res.status) window.open(`${PREVIEW_URL}/${res.data.guid}`, "_blank");
    });
  };

  const [openMCADrawer, setOpenMCADrawer] = useState(false);
  const toggleMCA = (index, id) => {
    if (openMCADrawer) {
      setOpenMCADrawer(false);
    } else {
      setEditInvestor(investorList.find((item) => item.invRid === id));
      setOpenMCADrawer(true);
      handleMenuClose(index);
    }
  };

  const [openNominationDrawer,setOpenNominationDrawer]=useState(false);
  const toggleNomination=(index,id)=>{
    if(openNominationDrawer){
      setOpenNominationDrawer(false);
    }else{
      setEditInvestor(investorList.find((item) => item.invRid === id));

      setOpenNominationDrawer(true);
      handleMenuClose(index);
    }
  }

  const [openInvestorLogin, setOpenInvestorLogin] = useState(false);
  const toggleInvestorLogin = (index, id) => {
    if (openInvestorLogin) {
      setOpenInvestorLogin(false);
    } else {
      setEditInvestor(investorList.find((item) => item.invRid === id));
      setOpenInvestorLogin(true);
      handleMenuClose(index);
    }
  };
  const handleInvestorLoginSave = () => {
    getInvestorList();
  };

  const getPartnersList = () => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const downloadFile = async (response) => {
    const blob = new Blob([response.data], { type: response.headers.get('content-type') });
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download' , response.headers.get('content-disposition').split('"')[1]);
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  const downloadInvestorList = (invStatus) => {
    apiInvestorListDownload({
        isActive: invStatus
    }).then((data) => {
        downloadFile(data);
        setAnchorElExport(null);
    })
    .catch((error) => {
        enqueueSnackbar(error,SNACKBAR_ERROR);
    });
  }

  return (
    <Layout title="Investors">
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>

      <MCADrawer
        open={openMCADrawer}
        toggleDrawer={toggleMCA}
        investor={editInvestor}
      />

      <NominationDrawer
        open={openNominationDrawer}
        toggleDrawer={toggleNomination}
        investor={editInvestor}
      />

      <InvestorInfo
        open={openInvestorInfo}
        onClose={toggleInvestorInfo}
        investor={editInvestor}
      />

      <InvestorLogin
        open={openInvestorLogin}
        toggleDialog={toggleInvestorLogin}
        investor={editInvestor}
        handleSave={handleInvestorLoginSave}
      />

      {userType === "ADMIN" && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}
      <InvestorModal
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        investor={editInvestor}
        handleSave={(investor) => handleSave(investor)}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-2">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{ border: "1px solid #000" }}
            >
              <div className="my-auto">
                <SearchIcon sx={{ cursor: "pointer" }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
            {userType === "ADMIN" && (
              <FormControl sx={{ width: 180 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  Filter By Partner
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={filterPartner}
                  onChange={(e) => setFilterPartner(e.target.value)}
                  input={<OutlinedInput label="Filter By Partner" />}
                  renderValue={(selected) => `${selected.length} Selected`}
                >
                  {partnerList.map((data, key) => (
                    <MenuItem key={key} value={data.partnerRid}>
                      <Checkbox
                        checked={filterPartner.indexOf(data.partnerRid) > -1}
                      />
                      <ListItemText primary={data.fullName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Tooltip title="Filter">
              <IconButton
                color="secondary"
                aria-controls={openFilterMenu ? "basic-popover" : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? "true" : undefined}
                onClick={(e) => setAnchorElFilter(e.currentTarget)}
              >
                <FilterAltIcon />
              </IconButton>
            </Tooltip>
            <Popover
              sx={{ boxSizing: "border-box" }}
              id="basic-popover"
              anchorEl={anchorElFilter}
              open={openFilterMenu}
              onClose={() => setAnchorElFilter(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: "50%",
                  padding: "20px",
                },
              }}
            >
              <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item xs={12}>
                  <span className="fs-large-semibold text-primary">Investor Filter</span>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">CKYC</span>
                    <RadioGroup
                      value={filterCkyc}
                      onChange={(e) => handleCkycFilter(parseInt(e.target.value))}
                      row
                    >
                      <FormControlLabel
                        value="-1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">All</span>}
                      />
                      <FormControlLabel
                        value="1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Verified</span>}
                      />
                      <FormControlLabel
                        value="0" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Not Verified</span>}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">MCA</span>
                    <RadioGroup
                      value={filterMca}
                      onChange={(e) => handleMcaFilter(parseInt(e.target.value))}
                      row
                    >
                      <FormControlLabel
                        value="-1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">All</span>}
                      />
                      <FormControlLabel
                        value="1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Signed</span>}
                      />
                      <FormControlLabel
                        value="0" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Not Signed</span>}
                      />
                      <FormControlLabel
                        value="2" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Partially Signed</span>}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">Stamp</span>
                    <RadioGroup
                      value={filterStamp}
                      onChange={(e) => handleStampFilter(parseInt(e.target.value))}
                      row
                    >
                      <FormControlLabel
                        value="-1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">All</span>}
                      />
                      <FormControlLabel
                        value="1" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Yes</span>}
                      />
                      <FormControlLabel
                        value="0" 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">No</span>}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">Investor Status</span>
                    <RadioGroup
                      value={dematFilters?.isActive === undefined ? -1 : dematFilters?.isActive}
                      onChange={(e) => {
                        setDematFilters((prev) => ({
                          ...prev,
                          isActive: e.target.value
                        }));
                      }}
                      row
                    >
                      <FormControlLabel
                        value={-1}
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">All</span>}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Active</span>}
                      />
                      <FormControlLabel
                        value={0} 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Inactive</span>}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">Demat</span>

                    <div className="d-flex gap-3 align-items-center">
                      <FormControlLabel
                        label={<span className="fs-normal-medium p-0 m-0">CML Upload Pending</span>}
                        control={
                          <Checkbox
                            checked={dematFilters?.cmlUploadPending === true}
                            onClick={() => setDematFilters((prev) => ({
                              ...prev,
                              cmlUploadPending: dematFilters?.cmlUploadPending === true ? null : true
                            }))}
                          />
                        }
                      />
                      <FormControlLabel
                        label={<span className="fs-normal-medium p-0 m-0">DP Name Pending</span>}
                        control={
                          <Checkbox
                            checked={dematFilters?.dpNamePending === true}
                            onClick={() => setDematFilters((prev) => ({
                              ...prev,
                              dpNamePending: dematFilters?.dpNamePending === true ? null : true
                            }))}
                          />
                        }
                      />
                      <FormControlLabel
                        label={<span className="fs-normal-medium p-0 m-0">Client ID Pending</span>}
                        control={
                          <Checkbox
                            checked={dematFilters?.clientIdPending === true}
                            onClick={() => setDematFilters((prev) => ({
                              ...prev,
                              clientIdPending: dematFilters?.clientIdPending === true ? null : true
                            }))}
                          />
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-flex gap-2 flex-column">
                    <span className="fs-normal-semibold text-primary">Demat Status</span>
                    <RadioGroup
                      value={dematFilters?.dematPending === undefined ? -1 : dematFilters?.dematPending}
                      onChange={(e) => {
                        setDematFilters((prev) => ({
                          ...prev,
                          dematPending: e.target.value
                        }));
                      }}
                      row
                    >
                      <FormControlLabel
                        value={-1}
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">All</span>}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Demat Pending</span>}
                      />
                      <FormControlLabel
                        value={0} 
                        control={<Radio />} 
                        label={<span className="fs-normal-medium p-0 m-0">Demat Completed</span>}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
              </Grid>
            </Popover>

            <Tooltip title="Sort">
              <IconButton
                color="secondary"
                // id="basic-button"
                aria-controls={openSortMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSortMenu ? "true" : undefined}
                onClick={(e) => setAnchorElSort(e.currentTarget)}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ boxSizing: "small" }}
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSortMenu}
              onClose={() => setAnchorElSort(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  width: "250px",
                },
              }}
            >
              <p className="fs-normal-semibold text-primary px-3 my-2">
                Investor Name
              </p>
              <RadioGroup
                value={sortInv}
                onChange={(e) => handleSortInv(parseInt(e.target.value))}
              >
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By ASC</p>
                  <Radio size="24px" value="1" />
                </MenuItem>
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By DESC</p>
                  <Radio size="24px" value="2" />
                </MenuItem>
              </RadioGroup>
              <Divider />
              <p className="fs-normal-semibold text-primary px-3 my-2">
                Investor Code
              </p>
              <RadioGroup
                value={sortInv}
                onChange={(e) => handleSortInv(parseInt(e.target.value))}
              >
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By ASC</p>
                  <Radio size="24px" value="3" />
                </MenuItem>
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By DESC</p>
                  <Radio size="24px" value="4" />
                </MenuItem>
              </RadioGroup>
              <Divider />
              <MenuItem onClick={() => handleSortInv(null)}>
                <p className="fs-normal-medium p-0 m-0">Clear</p>
              </MenuItem>
            </Menu>

            {/* <FormControl sx={{width: 150}} size="small">
              <InputLabel id="demo-simple-select-label">CKYC</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterCkyc}
                label="CKYC"
                onChange={(e) => setFilterCkyc(parseInt(e.target.value))}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="1">Verified</MenuItem>
                <MenuItem value="0">Not Verified</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{width: 150}} size="small">
              <InputLabel id="demo-simple-select-label">MCA</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterMca}
                label="MCA"
                onChange={(e) => setFilterMca(parseInt(e.target.value))}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="1">Signed</MenuItem>
                <MenuItem value="0">Not Signed</MenuItem>
              </Select>
            </FormControl> */}

            {userType === "ADMIN" && !isSubAdmin() && (
              <Button
                sx={{textTransform: 'none'}}
                aria-controls={Boolean(anchorElExport) ? 'menu-export' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorElExport) ? 'true' : undefined}
                onClick={(e) => setAnchorElExport(e.currentTarget)}
                startIcon={<GetAppIcon />}
              >
                <span className="fs-med-regular">Export to Excel</span>
              </Button> )}
            <Menu
              id="menu-export"
              anchorEl={anchorElExport}
              open={Boolean(anchorElExport)}
              onClose={() => setAnchorElExport(null)}
            >
              <MenuItem onClick={() => downloadInvestorList(null)}>
                <span className="fs-med-regular">Download All Investors</span>
              </MenuItem>
              <MenuItem onClick={() => downloadInvestorList(true)}>
                <span className="fs-med-regular">Download Active Investors</span>
              </MenuItem>
              <MenuItem onClick={() => downloadInvestorList(false)}>
                <span className="fs-med-regular">Download Inactive Investors</span>
              </MenuItem>
            </Menu>
          </div>
          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              {investorList.length > 0 ? (
                <table
                  className="table table-striped table-borderless w-100"
                  id="order-table"
                >
                  <thead>
                    <tr className="fs-normal-semibold text-center">
                      <th scope="col">
                        <p className="m-0">Actions</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Investor</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Code</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Mobile</p>
                      </th>
                      {/* <th scope="col">
                      <p className="m-0">Email</p>
                    </th> */}
                      <th scope="col">
                        <p className="m-0">CKYC</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">MCA</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Stamp</p>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody className="bg-table-blue">
                    {investorList.map((item, index) => {
                      return (
                        <tr
                          className="fs-normal-medium text-center"
                          key={item.invRid}
                        >
                          <td className="pt-1">
                            <div className="d-flex">
                                <IconButton onClick={() => toggleInvestorInfo(item)}>
                                      <InfoIcon fontSize="small" className="text-blue" />
                                </IconButton>
                             
                              {userType === "ADMIN" && (
                                <IconButton
                                  onClick={() => handleEdit(item.invRid)}
                                  aria-label="edit"
                                >
                                  <EditIcon className="text-blue" />
                                </IconButton>
                              )}
                    
                              {userType === "ADMIN" && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => openInvPreview(item.userRid)}
                                >
                                  <ShowChartIcon />
                                </IconButton>
                              )}
                            </div>
                          </td>
                          <td className="pt-3">
                            <div className="d-flex gap-1 align-items-center">
                              {item.loginEnabled ? (
                                <CircleIcon
                                  sx={{ fontSize: "10px", color: "#008000" }}
                                />
                              ) : (
                                <CircleIcon
                                  sx={{ fontSize: "10px", color: "#D3D3D3" }}
                                />
                              )}
                              <span>{item.fullName}</span>
                              <span className="ms-2">
                                {
                                  item?.active
                                  ? <Chip label={'Active'} size="small" color="success" variant="outlined" />
                                  : <Chip label={'Dormant'} size="small" color="warning" variant="outlined" />
                                }
                              </span>
                            </div>
                          </td>
                          <td className="pt-3">{item.invCode}</td>
                          <td className="pt-3">
                            {item.countryCode} {item.mobile}
                          </td>
                          {/* <td className="pt-3">{item.email}</td> */}
                          <td className="pt-3">
                            {item.ckycStatus ? "Verified" : "Not Verified"}
                          </td>
                          <td className="pt-3">
                            {item.mcaStatus === 1
                              ? "Signed"
                              : item.mcaStatus === 2
                              ? "Partially Signed"
                              : "Not Signed"}
                          </td>
                          <td className="pt-3">
                            {item.stampPaper ? "Yes" : "No"}
                          </td>
                            <td className="pt-1">
                              <IconButton
                                id="basic-button"
                                aria-controls={
                                  Boolean(anchorElMap[index])
                                    ? "basic-menu"
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  Boolean(anchorElMap[index])
                                    ? "true"
                                    : undefined
                                }
                                onClick={(e) => handleMenuOpen(e, index)}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElMap[index]}
                                open={Boolean(anchorElMap[index])}
                                elevation={1}
                                onClose={() => handleMenuClose(index)}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                color="secondary"
                              >
                                <MenuItem
                                sx={{minWidth: userType === 'PARTNER' ? '100px' : 'auto'}}
                                  className="fs-med-regular"
                                  onClick={() => toggleMCA(index, item.invRid)}
                                >
                                   MCA
                                </MenuItem>

                                <MenuItem
                                sx={{minWidth: userType === 'PARTNER' ? '100px' : 'auto'}}
                                  className="fs-med-regular"
                                  onClick={() => toggleNomination(index, item.invRid)}
                                >
                                   Nomination
                                </MenuItem>

                                {userType === "ADMIN" && (
                                <MenuItem
                                  className="fs-med-regular"
                                  onClick={() =>
                                    toggleInvestorLogin(index, item.invRid)
                                  }
                                >
                                  Investor Login
                                </MenuItem>
                                  )}
                                  {userType === "ADMIN" && !isSubAdmin() && (
                                <MenuItem
                                  className="fs-med-regular text-danger"
                                  onClick={() => {
                                    deleteUser(item);
                                    handleMenuClose(index);
                                  }}
                                >
                                  Delete Investor
                                </MenuItem>
                                )}
                              </Menu>
                            </td>
                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data..</p>
              )}
              {/* // <tr className="fs-normal-medium text-center">
                //   <td className="p-0">
                //     <div className="d-flex justify-content-center">
                //       <IconButton aria-label="edit">
                //         <EditIcon className="text-blue" />
                //       </IconButton>
                //       <IconButton aria-label="delete">
                //         <DeleteOutlineIcon className="text-danger" />
                //       </IconButton>
                //     </div>
                //   </td>
                //   <td className="py-auto">
                //     <Button
                //       variant="contained"
                //       className="styling-none w-50 blue-accent text-blue fs-small-semibold"
                //     >
                //       Open
                //     </Button>
                //   </td>
                //   <td className="pt-3">2841782712</td>
                //   <td className="pt-3">Project One</td>
                //   <td className="pt-3">56</td>
                //   <td className="pt-3">79,25,952</td>
                //   <td className="pt-3">2</td>
                //   <td className="pt-3">Pre-Seed</td>
                // </tr>
                // <tr className="fs-normal-medium text-center">
                //   <td className="p-0">
                //     <div className="d-flex justify-content-center">
                //       <IconButton onClick={handleEdit} aria-label="edit">
                //         <EditIcon className="text-blue" />
                //       </IconButton>
                //       <IconButton aria-label="delete">
                //         <DeleteOutlineIcon className="text-danger" />
                //       </IconButton>
                //     </div>
                //   </td>
                //   <td className="py-auto">
                //     <Button
                //       variant="contained"
                //       className="styling-none w-50 blue-accent text-blue fs-small-semibold"
                //     >
                //       Open
                //     </Button>
                //   </td>
                //   <td className="pt-3">2841782712</td>
                //   <td className="pt-3">Project One</td>
                //   <td className="pt-3">56</td>
                //   <td className="pt-3">79,25,952</td>
                //   <td className="pt-3">2</td>
                //   <td className="pt-3">Pre-Seed</td>
                // </tr> */}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Investor;
