import React, { useState, useEffect } from "react";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  apiDetailedFeeGenerateReport,
  apiFeeGenerateReport,
  apiFunds,
  apiInvestorFeeGenerated,
  apiInvestorOpportunity,
  apiSchemeSummary,
  getOpportunity,
} from "../../utils/api/api";
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { toLocalePrice } from "../../utils/StringUtil";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const FeeGenerated = ({ open, toggleDrawer, fundTotalObj }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [upFront, setUpFront] = useState(0.0);
  const [gst, setGST] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [investorList, setInvestorList] = useState([]);
  const [opportunityList, setOpportunityList] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const downloadFile = async (response) => {
    const blob = new Blob([response.data], {
      type: response.headers.get("content-type"),
    });
    const downloadLink = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute(
      "download",
      response.headers.get("content-disposition").split('"')[1]
    );
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const getOpportunityList = () => {
    apiSchemeSummary()
      .then((data) => {
        setOpportunityList(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getInvestorOpportunity = (param = {}) => {
    setIsLoading(true);
    
    param.oppRid = expanded;
   
    apiInvestorFeeGenerated(param)
      .then((data) => {
        setIsLoading(false);
        setInvestorList(data);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getFeeGeneratedFile = () => {
    setActionLoader(true);
    apiFeeGenerateReport({
      oppRid: null,
    })
      .then((data) => {
        setActionLoader(false);
        downloadFile(data);
        setAnchorEl(null);
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getDetailedFeeGeneratedFile = () => {
    setActionLoader(true);
    apiDetailedFeeGenerateReport({
      fundStatus: null,
    })
      .then((data) => {
        setActionLoader(false);
        downloadFile(data);
        setAnchorEl(null);
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  
  

  useEffect(() => {
    expanded !== null && getInvestorOpportunity();
  }, [expanded]);

  useEffect(() => {
    if (open) {
     
      getOpportunityList();
    }
  }, [open]);

  return (
    <DrawerLayout
      open={open}
      title="Fee Generated"
      closeAction={toggleDrawer}
      positiveAction={{ label: "Close", onClick: toggleDrawer }}
    >
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        <ScoreBoard
          label="Total Upfront Fee"
          value={toLocalePrice(fundTotalObj?.totalUpfrontFee)}
          flex={1}
        />
        <ScoreBoard
          label="Total Statutory Fees"
          value={toLocalePrice(fundTotalObj?.totalGst)}
          flex={1}
        />
        <ScoreBoard
          label="Total Additional Charges"
          value={toLocalePrice(fundTotalObj?.totalAdditionalCharges)}
          flex={1}
        />
        <ScoreBoard
          label="Partner Payments"
          value={toLocalePrice(fundTotalObj?.totalPartnerPayment)}
          flex={1}
        />
        <ScoreBoard
          label="Net Income"
          value={toLocalePrice(fundTotalObj?.totalNetIncome)}
          flex={1}
        />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" spacing={2}>
        <div className="d-flex justify-content-between align-items-center">
          <p className="section-label p-0 m-0">Scheme Wise</p>
          <div>
            <IconButton
              color="secondary"
              id="basic-button"
              aria-controls={anchorEl ? "basic-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              elevation={3}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="fs-large-regular" onClick={getFeeGeneratedFile}>
              Partner Payments - Scheme wise
              </MenuItem>
              <MenuItem className="fs-large-regular" onClick={getDetailedFeeGeneratedFile}>
              Partner Payments - Detailed
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Stack>
          {opportunityList.map((data, key) => {
            return (
              <Accordion
                key={data.oppRid}
                expanded={expanded === data.oppRid}
                onChange={handleChange(data.oppRid)}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon color='primary' />}
                  aria-controls={`panel-${data.oppRid}-content`}
                  id={`panel-${data.oppRid}-header`}
                >
                  <div className="d-flex gap-3 align-items-center w-100">
                    <div className="d-flex flex-grow-1">
                      <h5 className="fs-med-semibold p-0 m-0">
                        {data.oppTitle}
                      </h5>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <Chip
                        variant="outlined"
                        color="error"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Expenses : </span>
                            {/* {toLocalePrice(data.expenses)}<br/> */}
                            {toLocalePrice(data.partnerFee+data.schemeExpenses)}
                           
                          </div>
                        }
                      />
                      <Chip
                        variant="outlined"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Statutory Fees : </span>
                            {toLocalePrice(data.gst)}
                          </div>
                        }
                      />
                      <Chip
                        variant="outlined"
                        color="success"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Income : </span>
                            {toLocalePrice(data.upfrontAmount+(data.additionalCharges+data.dematCharges))}
                          </div>
                        }
                      />
                      {/* <Chip
                        variant="outlined"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">PP : </span>
                            {toLocalePrice(data.partnerFee)}
                          </div>
                        }
                      /> */}
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={
                          <div className="fs-normal-semibold">
                            <span className="fs-normal-regular">Net Income : </span>
                            {toLocalePrice((data.upfrontAmount+(data.additionalCharges+data.dematCharges))-(data.partnerFee+data.schemeExpenses))}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {expanded === data.oppRid && investorList.length > 0 ? (
                        <table className="table table-striped table-borderless p-0 m-0 w-100">
                          <thead>
                            <tr className="fs-normal-semibold text-center">
                              <th scope="col">
                                <p className="mb-2">Investor</p>
                              </th>
                              <th scope="col" colspan="2">
                                <p className="mb-2 text-center">Upfront</p>
                              </th>
                              <th scope="col">
                                <p className="mb-2 text-end">Statutory Fees</p>
                              </th>
                              <th scope="col">
                                <p className="mb-2 text-end">Additional Charges</p>
                              </th>
                              <th scope="col">
                                <p className="mb-2 text-end">Partner Payment</p>
                              </th>
                              <th scope="col">
                                <p className="mb-2 text-end">Net Income</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {investorList.map((data, key) => {
                              return (
                                <tr
                                  className="fs-normal-medium text-center"
                                  key={key}
                                >
                                  <td className="pt-2">
                                    {data.fullName}
                                    <span className="fs-small-regular text-muted ms-2">
                                      {data.invCode}
                                    </span>
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.upfrontAmount)}
                                  </td>
                                  <td className="pt-2 text-start">
                                    <span className="fs-small-regular text-muted">
                                      {toLocalePrice(data.upfront)}%
                                    </span>
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.gst)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.aditionalCharges)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.partnerFee)}
                                  </td>
                                  <td className="pt-2 text-end">
                                    {toLocalePrice(data.netIncome)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p className="fs-med-regular p-0 m-0">No Data</p>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      </Stack>
    </DrawerLayout>
  );
};

export default FeeGenerated;
